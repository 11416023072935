import { useLazyQuery } from '@apollo/react-hooks'
import { usePortalFeatures } from '@components/RoleBasedView'
import { IGetEventAttendeeData, IGetEventsInput } from '@interfaces/Events'
import { GET_EVENT_ATTENDEE } from '@queries/events'
import React, { useEffect } from 'react'

export interface IPEventAttendeeProvider {
  StartDateTime: string
  EndDateTime: string
  RecordId: string
  Type: string
}

export interface IEventAttendeeContext {
  RecordId?: string
  data?: IGetEventAttendeeData
  loading?: boolean
  called?: boolean
  error?: any
}

export const EventAttendeeContext = React.createContext<IEventAttendeeContext>({} as IEventAttendeeContext)

export const EventAttendeeProvider: React.FC<IPEventAttendeeProvider> = ({
  StartDateTime = '',
  EndDateTime = '',
  RecordId = '',
  Type = '',
  children,
}) => {
  const { state } = usePortalFeatures({
    configId: 'c::sidenav::events',
  })

  const [getEventAttendee, { data, loading, called, error }] = useLazyQuery<IGetEventAttendeeData, IGetEventsInput>(
    GET_EVENT_ATTENDEE,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (state.allowed && StartDateTime && EndDateTime && RecordId && Type) {
      getEventAttendee({
        variables: {
          StartDateTime,
          EndDateTime,
          RecordId,
          Type,
        },
      })
    }
  }, [state.allowed, StartDateTime, EndDateTime, RecordId, Type])

  return (
    <EventAttendeeContext.Provider value={{ RecordId, data, loading, called, error }}>
      {children}
    </EventAttendeeContext.Provider>
  )
}

export default EventAttendeeProvider
