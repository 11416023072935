import * as React from 'react'
import { Row } from 'react-styled-flexboxgrid'

import Collapsable from './Collapsable'
import { ListCardProps } from './interface'
import {
  Card,
  CollapsablePlaceholder,
  Content,
  ContentCol,
  IconBox,
  IconCol,
  IconLabel,
  Left,
  Right,
  RightCol,
} from './style'

const ListCard: React.FC<ListCardProps> = ({
  icon,
  iconLabel,
  iconLabelStyle,
  iconPosition = 'middle',
  children,
  rightContent,
  collapseContent,
  collapsable = false,
  color = 'info',
  fullColor,
  disableMargin,
  fullHeight,
  bordered,
  collapsableMargin = true,
  onCollapse,
  ...rest
}) => (
  <Card
    color={color}
    fullColor={fullColor}
    disableMargin={disableMargin}
    fullHeight={fullHeight}
    bordered={bordered}
    className="at-risk-card"
    {...rest}
  >
    <Row>
      <IconCol xs={12} lg={false} className="card-left-col" labelStyle={iconLabelStyle}>
        <Left iconPosition={iconPosition}>
          <IconBox>
            {icon}
            {iconLabel && <IconLabel labelStyle={iconLabelStyle}>{iconLabel}</IconLabel>}
          </IconBox>
        </Left>
      </IconCol>
      <IconCol xs={false} lg={1} className="card-left-col">
        <Left iconPosition={iconPosition}>
          <IconBox>
            {icon}
            {iconLabel && <IconLabel labelStyle={iconLabelStyle}>{iconLabel}</IconLabel>}
          </IconBox>
        </Left>
      </IconCol>
      <ContentCol xs={12} lg={rightContent ? 9 : 11}>
        <Content>{children}</Content>
      </ContentCol>
      {rightContent && (
        <>
          <RightCol xs={false} lg={2}>
            <Right>{rightContent}</Right>
          </RightCol>
          <RightCol xs={12} lg={false}>
            <Right>{rightContent}</Right>
          </RightCol>
        </>
      )}
    </Row>
    {collapsable ? (
      <Collapsable content={collapseContent} onCollapse={onCollapse} />
    ) : (
      <CollapsablePlaceholder withMargin={collapsableMargin} />
    )}
  </Card>
)

export default ListCard
