import { EVENT_TYPES, EventColor } from '@berlitzplatforms/micro.ui.calendar-item-event/lib'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled, { css, DefaultTheme } from 'styled-components'

import { radiusXxxl } from '@utils/constants'
import { CardProps, Expandable, IconLabelStyles, LeftTagProps, StatusProp } from './interface'
import { GROUP_CONVERSATION_CLASS } from '@utils/constants/events'

export const Block = styled.div`
  border-radius: ${({ theme }) => theme.space.xxxs};
  height: 100%;
  border-radius: ${({ theme }) => theme.radius.sm};
  border: 1px solid ${({ theme }) => theme.palette.gray100};
  background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: ${({ theme }) => `${theme.space.sm}`};
  padding-right: 0;
  padding-bottom: ${({ theme }) => theme.space.xs};
  margin-top: ${({ theme }) => `-${theme.space.xxs}`};
  margin-left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray40};

  &.with-indicators {
    justify-content: space-between;

    button {
      &:first-child {
        margin-right: ${({ theme }) => theme.space.xs};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 0;
    border: none;

    &.with-indicators {
      justify-content: flex-end;
    }
  }
`

export const LessoInfoItem = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: ${({ theme }) => theme.space.xs};
    color: ${({ theme }) => theme.palette.blueGray50};
  }

  .group-name-icon {
    align-self: flex-start;
  }

  .margin-right-8 {
    margin-right: ${({ theme }) => theme.space.xs};
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.brandPrimary};
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    &.charcoal {
      color: ${({ theme }) => theme.colors.text01};
      text-decoration: none;
      cursor: initial;
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    }
  }

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }

  &.dark-text {
    svg {
      color: ${({ theme }) => theme.colors.text02};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    .group-name-icon {
      align-self: center;
    }

    a,
    p {
      font-size: ${({ theme }) => theme.fontSize.sm};
    }
  }
`

export const StyledIcon = styled.span`
  span {
    width: ${({ theme }) => theme.layout.xs};
    height: ${({ theme }) => theme.layout.xs};
    font-size: ${({ theme }) => theme.fontSize.xs};
    border-radius: ${radiusXxxl};
    background-color: ${({ theme }) => theme.colors.brandSecondary};
    display: flex;
    vertical-align: middle;
    margin-left: ${({ theme }) => theme.space.xs};

    svg {
      display: block;
      margin: auto;
      margin-top: ${({ theme }) => theme.space.xxs};
    }
  }
`

const CompletedBG = css`
  background-color: ${({ theme }) => theme.palette.blueGray50};
`

const PendingBG = css`
  background-color: ${({ theme }) => theme.palette.purple100};
`

const CancelledBG = css`
  background-color: ${({ theme }) => theme.colors.uiError};
`

export const Status = styled.div<StatusProp>`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  display: flex;
  align-items: center;
  padding: 0px ${({ theme }) => theme.space.xs};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};

  ${({ theme, pending }) =>
    pending &&
    css`
      margin-left: ${theme.space.md};
    `}

  ${StyledIcon} {
    span {
      ${({ completed }) => completed && CompletedBG}
      ${({ pending }) => pending && PendingBG}
      ${({ cancelled }) => cancelled && CancelledBG}
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      span {
        margin-right: ${({ theme }) => theme.space.xs};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0;
  }
`

export const Indicators = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Time = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    a,
    p {
      font-size: ${({ theme }) => theme.fontSize.sm};
    }
  }
`

export const TimeCol = styled(Col)``

export const LessonInfoRow = styled(Row)`
  padding: ${({ theme }) => `${theme.space.sm} !important`};

  ${Col} {
    padding-bottom: ${({ theme }) => theme.space.xxs};
    padding-right: 0;
    padding-left: 0;
  }

  ${TimeCol} {
    padding-bottom: ${({ theme }) => theme.space.sm};
  }

  .pending-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
  }
`

export const Right = styled.div`
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.sm}`};
  background-color: ${({ theme }) => theme.palette.white};
  border-left: 1px solid ${({ theme }) => theme.palette.gray40};
  height: 100%;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
`

export const RightCol = styled(Col)`
  padding: 0;

  ${({ xs }) =>
    xs === 12 &&
    css`
      ${Right} {
        border-left: none;
        border-top: 1px solid ${({ theme }) => theme.palette.gray40};
        border-radius: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    `}
`

export const Content = styled.div`
  height: 100%;
  padding: ${({ theme }) => `${theme.space.xs} 0`};
`

export const ContentCol = styled(Col)`
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  background-color: ${({ theme }) => theme.palette.white};
  position: relative;
  z-index: 1;

  ${({ lg }) =>
    lg === 11 &&
    css`
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    `}
`

const getEventColors = (name: string, theme: DefaultTheme) => {
  const EventColors: EventColor = {
    Orientation: {
      bg: theme.palette.blue10,
      icon: theme.palette.blue100,
      disabled: theme.palette.blue30,
    },
    'Technical check': {
      bg: theme.palette.purple10,
      icon: theme.palette.purple80,
      disabled: theme.palette.purple30,
    },
    Webinar: {
      bg: theme.palette.green10,
      icon: theme.palette.green100,
      disabled: theme.palette.green30,
    },
    'Counselling session': {
      bg: theme.palette.red10,
      icon: theme.palette.red100,
      disabled: theme.palette.red30,
    },
    Meeting: {
      bg: theme.palette.warning10,
      icon: theme.palette.warning100,
      disabled: theme.palette.warning30,
    },
    Activity: {
      bg: theme.palette.warning10,
      icon: theme.palette.warning100,
      disabled: theme.palette.warning30,
    },
    [GROUP_CONVERSATION_CLASS]: {
      bg: theme.palette.warning10,
      icon: theme.palette.warning100,
      disabled: theme.palette.warning30,
    },
  }
  const isEvent = EVENT_TYPES.some((et) => et.toLowerCase() === name?.toLowerCase())

  return isEvent || name === GROUP_CONVERSATION_CLASS
    ? EventColors[name || '']
    : {
        bg: theme.palette.powder,
        icon: theme.palette.purple60,
        disabled: theme.palette.gray100,
      }
}

export const LessonTypeBox = styled.div<{ name?: string; labelStyle?: IconLabelStyles }>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  .icon {
    ${({ theme, name }) => {
      const eventColor = getEventColors(name as string, theme)
      return `
        background-color: ${eventColor.bg};
        width: 48px;
        height: 48px;
        border-radius: ${theme.layout.xxxl};
        color: ${eventColor.icon};
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
      `
    }}
  }

  p {
    margin-left: ${({ theme }) => theme.space.xs};
    font-size: ${({ theme }) => theme.fontSize.xxxs};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.palette.blueGray60};
    padding-left: ${({ theme }) => theme.space.xxxs};
    padding-right: ${({ theme }) => theme.space.xxxs};

    &.dark-text {
      color: ${({ theme }) => theme.colors.brandSecondaryContrast};
    }
  }

  &.dark-icon {
    ${({ theme, name }) => `
      .icon {
        background-color: ${theme.palette.powder};
        svg {
          color: ${getEventColors(name as string, theme).disabled};
        }
      }
    `}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: left;
    justify-content: space-between;
    text-align: center;

    .icon {
      margin: auto;
      width: 56px;
      height: 56px;
    }

    p {
      width: 100%;
      margin-left: ${({ theme }) => theme.space.xs};
      ${({ theme, labelStyle }) => (labelStyle === 'badge' ? '' : `margin-top: ${theme.space.xs};`)}
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .icon {
      margin: auto;
      width: 56px;
      height: 56px;
    }

    p {
      margin-left: 0;
      margin-top: ${({ theme }) => theme.space.xs};
      width: 100%;
    }
  }
`

export const IconLabel = styled.div<{ labelStyle?: IconLabelStyles }>`
  margin-top: ${({ theme }) => theme.space.xs};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${({ labelStyle, theme }) =>
    labelStyle === 'badge'
      ? `
    padding: ${theme.space.xxxs} ${theme.space.xxs};
    color : ${theme.colors.brandPrimaryContrast};
    background-color: ${theme.colors.brandPrimary};
    border-radius:${theme.space.xxxs};
  `
      : ''}
`

export const IconCircle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border-radius: ${({ theme }) => theme.layout.xxxl};
  background-color: ${({ theme }) => theme.palette.success100};
  vertical-align: middle;
  border: 1px solid ${({ theme }) => theme.palette.gray100};
`

export const IconBox = styled.div`
  padding: ${({ theme }) => `${theme.space.md} ${theme.space.xs}`};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
`

export const Left = styled.div<LeftTagProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${({ iconPosition }) =>
    iconPosition === 'top' &&
    css`
      justify-content: flex-start;
    `}

  ${({ iconPosition }) =>
    iconPosition === 'bottom' &&
    css`
      justify-content: flex-end;
    `}
`

export const IconCol = styled(Col)<{ labelStyle?: IconLabelStyles }>`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.powder};
  border-right: 1px solid ${({ theme }) => theme.palette.gray40};
  border-radius: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  ${({ xs, labelStyle }) =>
    xs === 12 &&
    css`
      padding: ${({ theme }) => `${theme.space.xxs} ${theme.space.sm}`};
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.palette.gray40};
      border-radius: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      ${Left} {
        flex-direction: row;
        justify-content: flex-start;

        ${IconBox} {
          flex-direction: row;
          justify-content: space-between;
          ${IconCircle} {
            width: 38px;
            height: 38px;

            svg {
              font-size: ${({ theme }) => theme.fontSize.xs};
            }
          }

          ${IconLabel} {
            margin-top: 0;
            margin-left: ${({ theme }) => theme.space.xs};
            text-align: left;
            ${labelStyle === 'badge' ? 'margin-right: auto;' : ''}

            @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
              ${labelStyle === 'badge' ? '' : 'width: calc(100% - 60px);'}
            }
          }
        }
      }
    `}
`

export const ExpanderIcon = styled.span<Expandable>`
  align-items: center;
  display: flex;
  height: 100%;
  margin-right: ${({ theme }) => theme.space.xxs};
  transform: rotate(0deg);
  transition: all ${({ theme }) => theme.expander.transition};
  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(-180deg);
    `};
`

export const CollapsableContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.blue20};
`

export const CollapsableRow = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  border-bottom-right-radius: ${({ theme }) => theme.radius.md};
  border-bottom-left-radius: ${({ theme }) => theme.radius.md};

  .no-padding {
    padding: 0;
  }
`

export const CollapsablePlaceholder = styled.div<{ withMargin: boolean }>`
  ${({ withMargin, theme }) =>
    withMargin &&
    css`
      height: ${theme.space.lg};
    `}
`

export const CollapsableContent = styled(Col)`
  background-color: ${({ theme }) => theme.palette.white};
  padding: 0;
`

export const Card = styled.div<CardProps>`
  margin-bottom: ${({ theme }) => theme.space.md};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid ${({ theme }) => theme.palette.gray100};
      border-radius: 4px;
    `}

  ${({ disableMargin }) =>
    disableMargin &&
    css`
      margin-bottom: 0;
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.palette.gray100};
    `}

  ${({ color }) =>
    color === 'info' &&
    css`
      border-radius: ${({ theme }) => theme.radius.md};

      ${Block} {
        border-radius: ${({ theme }) => theme.radius.md};
        background-color: ${({ theme }) => theme.palette.info10};
      }

      ${IconCol} {
        background-color: ${({ theme }) => theme.colors.gray40};
      }

      ${ContentCol} {
        background-color: ${({ theme }) => theme.colors.white};
      }

      ${Right} {
        background-color: ${({ theme }) => theme.colors.white};
      }

      ${CollapsableContainer} {
        background-color: ${({ theme }) => theme.palette.blue20};
      }

      ${CollapsablePlaceholder} {
        background-color: ${({ theme }) => theme.colors.white};
      }
    `}

  ${({ color }) =>
    color === 'disabled' &&
    css`
      border: 2px dashed ${({ theme }) => theme.palette.blueGray20};
      border-radius: ${({ theme }) => theme.radius.sm};
      border-radius: 4px;

      ${Block} {
        border: none;
        border-radius: ${({ theme }) => theme.radius.sm};
        background-color: ${({ theme }) => theme.colors.ui02};
      }

      ${IconCol} {
        background-color: ${({ theme }) => theme.colors.ui02};
      }

      ${ContentCol} {
        background-color: ${({ theme }) => theme.colors.ui02};
      }

      ${Right} {
        background-color: ${({ theme }) => theme.colors.ui02};
      }

      ${CollapsableContainer} {
        background-color: ${({ theme }) => theme.palette.gray100};
      }

      ${CollapsablePlaceholder} {
        background-color: ${({ theme }) => theme.colors.ui02};
      }
    `}

  ${({ color, fullColor }) =>
    color === 'error' &&
    css`
      border: 1px solid ${({ theme }) => theme.palette.danger100};
      border-radius: ${({ theme }) => theme.radius.lg};

      ${Block} {
        border: 1px solid ${({ theme }) => theme.palette.danger100};
        border-radius: ${({ theme }) => theme.radius.lg};
        background-color: ${({ theme }) => theme.colors.white};
      }

      ${IconCol} {
        background-color: ${({ theme }) => theme.colors.gray40};
        border-top-left-radius: ${({ theme }) => theme.radius.lg};
        border-bottom-left-radius: ${({ theme }) => theme.radius.lg};

        ${fullColor &&
        css`
          background-color: ${({ theme }) => theme.palette.red10};
          border-color: ${({ theme }) => theme.palette.danger100};
        `}
      }

      ${ContentCol} {
        background-color: ${({ theme }) => theme.colors.white};
        border-top-right-radius: ${({ theme }) => theme.radius.lg};
        border-bottom-right-radius: ${({ theme }) => theme.radius.lg};

        ${fullColor &&
        css`
          background-color: ${({ theme }) => theme.palette.red10};
          border-color: ${({ theme }) => theme.palette.danger100};
        `}
      }

      ${Right} {
        background-color: ${({ theme }) => theme.colors.white};

        ${fullColor &&
        css`
          background-color: ${({ theme }) => theme.palette.red10};
          border-color: ${({ theme }) => theme.palette.danger100};
        `}
      }

      ${CollapsableContainer} {
        background-color: ${({ theme }) => theme.palette.danger10};
      }

      ${CollapsablePlaceholder} {
        background-color: ${({ theme }) => theme.palette.danger10};
      }
    `}

  ${({ color, fullColor }) =>
    color === 'warning' &&
    css`
      border: 1px solid #db832b;
      border-radius: ${({ theme }) => theme.radius.md};

      ${Block} {
        border: 1px solid #db832b;
        border-radius: ${({ theme }) => theme.radius.md};
        background-color: ${({ theme }) => theme.colors.white};
      }

      ${IconCol} {
        background-color: ${({ theme }) => theme.colors.gray40};

        ${fullColor &&
        css`
          background-color: ${({ theme }) => theme.palette.warning10};
          border-color: #db832b;

          svg {
            color: ${({ theme }) => theme.palette.warning60};
          }
        `}
      }

      ${ContentCol} {
        background-color: ${({ theme }) => theme.colors.white};

        ${fullColor &&
        css`
          background-color: ${({ theme }) => theme.palette.warning10};
          border-color: #db832b;
        `}
      }

      ${Right} {
        background-color: ${({ theme }) => theme.colors.white};

        ${fullColor &&
        css`
          background-color: ${({ theme }) => theme.palette.warning10};
          border-color: #db832b;
        `}
      }

      ${CollapsableContainer} {
        background-color: ${({ theme }) => theme.palette.warning10};
      }

      ${CollapsablePlaceholder} {
        background-color: ${({ theme }) => theme.palette.warning10};
      }
    `}

  ${({ color, fullColor }) =>
    color === 'pending' &&
    css`
      border: 1px solid #6538a2;
      border-radius: ${({ theme }) => theme.radius.lg};

      ${Block} {
        border: 1px solid #6538a2;
        border-radius: ${({ theme }) => theme.radius.lg};
        background-color: ${({ theme }) => theme.colors.white};
      }

      ${IconCol} {
        background-color: ${({ theme }) => theme.colors.gray40};
        border-top-left-radius: ${({ theme }) => theme.radius.lg};
        border-bottom-left-radius: ${({ theme }) => theme.radius.lg};

        ${fullColor &&
        css`
          background-color: ${({ theme }) => theme.palette.purple10};
          border-color: #6538a2;

          svg {
            color: ${({ theme }) => theme.palette.purple100};
          }
        `}
      }

      ${ContentCol} {
        background-color: ${({ theme }) => theme.colors.white};
        border-top-right-radius: ${({ theme }) => theme.radius.lg};
        border-bottom-right-radius: ${({ theme }) => theme.radius.lg};

        ${fullColor &&
        css`
          background-color: ${({ theme }) => theme.palette.purple10};
          border-color: #6538a2;
        `}
      }

      ${Right} {
        background-color: ${({ theme }) => theme.colors.white};
        padding-right: 0;

        ${fullColor &&
        css`
          background-color: ${({ theme }) => theme.palette.purple10};
          border-color: #6538a2;
        `}
      }

      ${CollapsableContainer} {
        background-color: ${({ theme }) => theme.palette.purple10};
      }
    `}

  ${Row} {
    margin: 0;
    padding: 0;
  }

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;

      ${Row} {
        height: 100%;
      }

      ${IconCol} {
        height: 72px;
      }

      ${ContentCol} {
        height: calc(100% - 72px);

        ${Row} {
          height: initial;
        }
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        ${IconCol} {
          height: 100%;
        }

        ${ContentCol} {
          height: 100%;
        }
      }
    `}
`

export const LessonDate = styled.div`
  color: ${({ theme }) => theme.palette.blueGray60};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  margin-bottom: -${({ theme }) => theme.space.sm};
`

export const TravelTime = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.space.md};
  font-size: ${({ theme }) => theme.fontSize.xxs};

  svg {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`

export const PendingButtons = styled.div`
  margin-right: ${({ theme }) => theme.space.md};

  button {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ghost {
    color: ${({ theme }) => theme.colors.brandPrimary};
    margin-top: ${({ theme }) => theme.space.xxs};
  }
`
