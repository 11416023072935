import gql from 'graphql-tag'

export const GET_EVENTS = gql`
  query GetEvents($StartDateTime: String!, $EndDateTime: String!, $RecordId: String!, $Type: String!) {
    getEvents(StartDateTime: $StartDateTime, EndDateTime: $EndDateTime, RecordId: $RecordId, Type: $Type) {
      berlitzEventInfo {
        Description
        EndDatetime
        EventStatus
        EventType
        Hosts {
          Id
          Name
          Avatar
          Type
        }
        Id
        IsRegistered
        Language
        Name
        SeatsLeft
        StartDatetime
        EventDisplayName
      }
    }
  }
`

export const GET_EVENT_ATTENDEE = gql`
  query GetBerlitzEventAttendee($StartDateTime: String!, $EndDateTime: String!, $RecordId: String!, $Type: String!) {
    getEventAttendeev2(StartDateTime: $StartDateTime, EndDateTime: $EndDateTime, RecordId: $RecordId, Type: $Type) {
      berlitzEventAttendeeInfo {
        VirtualClassRoomProvider
        StartDatetime
        SeatsLeft
        Role
        Language
        IsPaid
        Hosts {
          Id
          Name
          Avatar
          Type
        }
        EventType
        EventStatus
        EventName
        EventId
        EventAttendeeId
        EndDatetime
        Audience
        AttendeeStatus
        OnlineLessonURL
        EventDisplayName
        TopicLPID
        TopicUnitId
      }
    }
  }
`
