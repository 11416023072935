import Button from '@berlitz/button'
import { H2, H3 } from '@berlitz/globals'
import { Tick } from '@berlitz/icons'
import Spinner from '@berlitz/spinner'
import { Grid } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import Resizable from '../../../interfaces/Resizable'

export const MenuTabBox = styled.div`
  margin: ${({ theme }) => `${theme.space.lg} 0 ${theme.space.xxs}`};
  width: 100%;
  min-width: max-content;
`

export const PaneHeader = styled.div`
  margin-top: ${({ theme }) => `${theme.space.lg}`};
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  ${H3} {
    width: max-content;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  button {
    border: none;
  }
`

export const Section = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.palette.blue20};
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.lg}`};
`

export const Content = styled.div`
  position: relative;
  border: 2px solid ${({ theme }) => theme.palette.blue20};
  border-radius: ${({ theme }) => theme.radius.md};
  margin-bottom: ${({ theme }) => theme.space.md};

  ${Section} {
    &:last-child {
      border: none;
    }
  }
`

export const DimmerBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.blue20};
  z-index: 998;
  opacity: 0.7;
`

export const SpinnerContainer = styled.div`
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;

  ${H2} {
    margin-top: ${({ theme }) => theme.space.lg};
  }
`

export const SpinnerWrapper = styled.div<Resizable>`
  margin: ${({ height, theme }) => (height ? height : theme.layout.xxl)} auto 0;
  display: flex;
  align-items: center;
  align-content: center;
  width: 116px;
  height: 116px;
  padding: ${({ theme }) => theme.space.md};
  border-radius: ${({ theme }) => theme.radius.sm};
  background-color: ${({ theme }) => theme.palette.white};
`

export const StyledSpinner = styled(Spinner)`
  display: block;
  text-align: center;
  margin: 0 auto;
  border-top-width: ${({ theme }) => theme.space.xs};
  border-right-width: ${({ theme }) => theme.space.xs};
  border-bottom-width: ${({ theme }) => theme.space.xs};
  border-left-width: ${({ theme }) => theme.space.xs};
  width: ${({ theme }) => theme.layout.lg};
  height: ${({ theme }) => theme.layout.lg};
`

export const StyledTick = styled(Tick)`
  font-size: ${({ theme }) => theme.layout.lg};
  margin-left: ${({ theme }) => theme.space.xs};
  color: ${({ theme }) => theme.palette.success100};
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.layout.lg};
`
export const Back = styled(Button)`
  padding: ${({ theme }) => `0 ${theme.space.xs} 0 ${theme.space.xxs}`};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  border: 0;
  margin-bottom: ${({ theme }) => theme.space.md};
  svg {
    color: ${({ theme }) => theme.colors.brandPrimaryContrast};
    transform: rotate(180deg);
    margin-top: ${({ theme }) => theme.space.xxxs};
  }
  &:focus {
    outline: 0;
  }
`

export const Body = styled(Grid)`
  padding: 0;
`

export const LastMaterialContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledBadge = styled.span`
  ${({ theme }) => `
    font-size: ${theme.fontSize.xxxs};
    font-weight: ${theme.fontWeight.bold};
    background-color: ${theme.colors.brandPrimary};
    color: ${theme.colors.brandPrimaryContrast};
    padding: ${theme.space.xxs};
    border-radius: ${theme.radius.sm};
    margin-right: ${theme.space.xs};
  `}
`

export const LastMaterialText = styled.span`
  ${({ theme }) => `
    font-size: ${theme.fontSize.xxs};
    font-weight: ${theme.fontWeight.bold};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.49px;
  `}
`
