import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import Button from '../Button'

export const MaterialContainer = styled.div`
  margin-top: ${({ theme }) => theme.space.md};
  margin-bottom: ${({ theme }) => theme.space.md};
`

export const StyledRow = styled(Row)``

export const StyledCol = styled(Col)`
  padding: 0;
  margin-right: ${({ theme }) => theme.space.sm};
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledLabel = styled.label`
  margin-bottom: ${({ theme }) => theme.space.xs};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-right: ${({ theme }) => theme.space.xs};
`

export const StyledButton = styled(Button)`
  ${({ theme }) => `
    margin-top: ${theme.space.md};
    @media (min-width: 769px) {
      width: auto;
    }
  `}
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-right: ${({ theme }) => theme.space.xs};
`

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  padding: ${({ theme }) => theme.space.lg};
  margin-right: ${({ theme }) => theme.space.xxs};
  border-radius: 3px;

  &.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .select-loader {
    display: flex;

    div:first-child {
      margin-right: ${({ theme }) => theme.space.md};
    }
  }
`

export const LastMaterialsText = styled.div`
  line-height: 1.1em;
  margin-top: 6px;
  ${({ theme }) => `
    font-size: ${theme.fontSize.xxs};
  `}
`

export const MaterialItemContainer = styled(ItemContainer)`
  ${({ theme }) => `
    padding-bottom: ${theme.space.sm};
  `}
`
