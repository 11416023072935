import Spacer from '@berlitz/spacer'
import random from 'lodash/random'
import * as React from 'react'
import ContentLoader from 'react-content-loader'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'

import Resizable from '../../interfaces/Resizable'

const Inline = styled.div`
  display: flex;
  div {
    margin-right: ${({ theme }) => theme.space.xs};
  }
`

const LoadUI: React.FC<Resizable> = ({ height, width = '100%' }) => (
  <ContentLoader
    height={42}
    width={500}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ height, width }}
  />
)

const ListItemLoader: React.FC = () => (
  <Row>
    <Col xs={6} md={3}>
      <LoadUI height="25px" width={`${random(70, 100)}%`} />
      <Spacer size="xxs" />
    </Col>
    <Col xs={12} />
    <Col xs={5} md={2}>
      <Inline>
        <div>
          <LoadUI height="20px" width="25px" />
        </div>
        <LoadUI height="20px" width={`${random(50, 100)}%`} />
      </Inline>
    </Col>
    <Col xs={12} />
    <Col xs={5} md={2}>
      <Inline>
        <div>
          <LoadUI height="20px" width="25px" />
        </div>
        <LoadUI height="20px" width={`${random(50, 100)}%`} />
      </Inline>
    </Col>
    <Col xs={12} />
    <Col xs={5} md={2}>
      <Inline>
        <div>
          <LoadUI height="20px" width="25px" />
        </div>
        <LoadUI height="20px" width={`${random(70, 100)}%`} />
      </Inline>
    </Col>
  </Row>
)

export default ListItemLoader
