import * as React from 'react'

import { CardProps } from './interface'
import { Block, Card } from './style'

const ListCardBlock: React.FC<CardProps> = ({ children, color = 'info', disableMargin, fullHeight, ...rest }) => (
  <Card color={color} disableMargin={disableMargin} fullHeight {...rest}>
    <Block>{children}</Block>
  </Card>
)

export default ListCardBlock
