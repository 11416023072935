import * as React from 'react'
import ContentLoader from 'react-content-loader'
import { Row } from 'react-styled-flexboxgrid'

import Resizable from '../../interfaces/Resizable'
import { ListCardLoaderProps } from './interface'
import ListItemLoader from './ListItemLoader'
import { Card, Content, ContentCol, IconBox, IconCol, IconLabel, Left, Right, RightCol } from './style'

const XSIconLoader: React.FC<Resizable> = ({ height, width }) => (
  <ContentLoader
    height={38}
    width={38}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ height, width }}
  >
    <circle cx="20" cy="20" r="19" />
  </ContentLoader>
)

const MDIconLoader: React.FC<Resizable> = ({ height, width }) => (
  <ContentLoader
    height={54}
    width={54}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ height, width }}
  >
    <circle cx="28" cy="28" r="27" />
  </ContentLoader>
)

const TextLoader: React.FC<Resizable> = ({ height, width = '100%' }) => (
  <ContentLoader
    height={42}
    width={500}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ height, width }}
  />
)

const ListCardLoader: React.FC<ListCardLoaderProps> = ({ children, rightContent }) => (
  <Card>
    <Row>
      <IconCol xs={12} lg={false}>
        <Left>
          <IconBox>
            <XSIconLoader height="38px" />
            <IconLabel>
              <TextLoader height="21px" width="60%" />
            </IconLabel>
          </IconBox>
        </Left>
      </IconCol>
      <IconCol xs={false} lg={1}>
        <Left>
          <IconBox>
            <MDIconLoader height="54px" />
            <IconLabel>
              <TextLoader height="21px" width="80%" />
            </IconLabel>
          </IconBox>
        </Left>
      </IconCol>
      <ContentCol xs={12} lg={rightContent ? 9 : 11}>
        <Content>{children ? children : <ListItemLoader />}</Content>
      </ContentCol>
      {rightContent && (
        <>
          <RightCol xs={false} lg={2}>
            <Right>{rightContent}</Right>
          </RightCol>
          <RightCol xs={12} lg={false}>
            <Right>{rightContent}</Right>
          </RightCol>
        </>
      )}
    </Row>
  </Card>
)

export default ListCardLoader
