export const ALL = 'All'
export const ORIENTATION = 'Orientation'
export const GETTING_STARTED = 'Getting started'
export const TECHNICAL_CHECK = 'Technical check'
export const ACTIVITY = 'Activity'
export const MEETING = 'Meeting'
export const COUNSELLING_SESSION = 'Counselling session'
export const GROUP_CONVERSATION_CLASS = 'Group Conversation Class'

export const EVENT_TYPES = {
  ALL,
  ORIENTATION,
  GETTING_STARTED,
  TECHNICAL_CHECK,
  ACTIVITY,
  MEETING,
  COUNSELLING_SESSION,
  GROUP_CONVERSATION_CLASS,
}

export const NEW = 'New'
export const FULL = 'Full'
export const ACTIVE = 'Active'
export const PENDING = 'Pending'

export const EVENT_STATUS = {
  NEW,
  FULL,
  ACTIVE,
  PENDING,
}

export const REGISTERED = 'Registered'
export const CANCELLED = 'Cancelled'
export const ATTENDED = 'Attended'

export const ATTENDEE_STATUS = {
  REGISTERED,
  CANCELLED,
  ATTENDED,
}

export const LANGUAGE = 'English'

export const EVENT_DEFAULTS = {
  LANGUAGE,
}
