import Button from '@berlitz/button'
import { H3 } from '@berlitz/globals'
import { FirstClass, Tick } from '@berlitz/icons'
import { Card, Content as ListCardContent } from '@components/ListCard/style'
import { Status as StyledStatus } from '@components/ListCard/style'
import { Wrapper } from '@components/ViewMaterial/style'
import { Section as LessonRegistrationSection } from '@layouts/LessonRegistration/Layout/style'
import { radiusXxxl } from '@utils/constants'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled, { css } from 'styled-components'
import { StatusProp } from './interface'

export const LessonTypeBox = styled.div`
  .icon {
    background-color: ${({ theme }) => theme.palette.powder};
    width: 56px;
    height: 56px;
    border-radius: ${({ theme }) => theme.layout.xxxl};
    margin: auto;
    margin-right: ${({ theme }) => theme.space.xs};
    color: ${({ theme }) => theme.palette.purple60};
    display: inline-block;

    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.14);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
      margin: auto;
    }

    svg {
      margin: auto;
      margin-top: ${({ theme }) => theme.space.md};
      margin-left: ${({ theme }) => theme.space.md};
    }
  }

  p {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    margin-top: ${({ theme }) => theme.space.md};
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.palette.blueGray60};

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: ${({ theme }) => theme.space.xs};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      display: block;
      font-size: ${({ theme }) => theme.fontSize.xxxs};
    }
  }
`
export const Container = styled.div`
  padding-top: ${({ theme }) => theme.space.xs};

  ${H3} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-bottom: ${({ theme }) => theme.space.sm};
  }

  ${Card} {
    margin-bottom: 0;
    box-shadow: none;

    ${ListCardContent} {
      padding: 0;
    }
  }
`

export const IconContainer = styled(Col)`
  padding: 0;

  svg {
    width: 22.4px;
  }

  ${({ xs }) =>
    xs === 12 &&
    css`
      svg {
        width: 24px;
      }
    `}
`

export const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.no-padding-left {
    padding-left: 0;
  }
`

export const Time = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const MyLessonEvent = styled.div`
  ${({ theme }) => `
    #delivery-language {
      font-size: ${theme.fontSize.xxxs};
      font-weight: ${theme.fontWeight.bold};
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.42px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      #language {
        background-color: ${theme.palette.purple100};
        padding: 4px 4px 3px;
        border-radius: 2px;
        margin-left: 3px;
        color: ${theme.palette.white};
      }
    }

    #date {
      color: ${theme.palette.blueGray70};
      font-size: ${theme.fontSize.xxs};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: 0.49px;
      margin-bottom: 3px;
    }

    #time {
      color: ${theme.palette.charcoal};
      font-size: ${theme.fontSize.xs};
      font-weight: ${theme.fontWeight.bold};
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.56px;
      margin-bottom: 2px;
    }

    .with-name {
      #name {
        color: ${theme.palette.charcoal};
        font-size: ${theme.fontSize.sm};
        font-weight: ${theme.fontWeight.bold};
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.49px;
        margin-bottom: 3px;
      }

      .date-time {
        display: flex;
        align-items: center;

        #middot {
          color: ${theme.palette.blueGray70};
          font-size: ${theme.fontSize.xxs};
          font-weight: ${theme.fontWeight.bold};
          padding: 0 8px;
        }

        #date, #time {
          color: ${theme.palette.blueGray70};
          font-size: ${theme.fontSize.xxs};
          font-weight: ${theme.fontWeight.bold};
        }
      }
    }

    a#more-info {
      color: ${theme.palette.blue100};
      text-align: right;
      font-size: ${theme.fontSize.xxs};
      font-weight: ${theme.fontWeight.bold};
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      cursor: pointer;
      text-decoration: underline;
    }
  `}
`

export const LessonProfile = styled.div<{ longContent?: boolean }>`
  display: flex;
  align-items: center;

  .room-to-be-confirmed {
    span {
      color: ${({ theme }) => theme.colors.text00};
    }
  }

  .room-to-be-confirmed-tooltip {
    svg {
      color: ${({ theme }) => theme.colors.brandPrimary};
    }
  }

  span {
    display: inline-flex;
    align-items: center;
    margin-right: ${({ theme }) => theme.space.xs};
    color: ${({ theme }) => theme.palette.blueGray50};
  }

  a {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    vertical-align: top;
    color: ${({ theme }) => theme.palette.blue100};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  p {
    margin: 0;
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }

  ${({ longContent }) =>
    longContent
      ? `
      p {
        padding-top: 2.5px;
      }
      align-items: flex-start;
    `
      : ''}
`

export const StyledCol = styled(Col)`
  margin-bottom: ${({ theme }) => theme.space.sm};

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  &.margin-bottom-xxs {
    margin-bottom: ${({ theme }) => theme.space.xxs};
  }

  &.no-margin {
    margin: 0;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.no-padding-left {
    padding-left: 0;
  }

  &.no-padding {
    padding: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: ${({ theme }) => theme.space.xxs};
  }
`

export const StyledIcon = styled.span`
  span {
    width: ${({ theme }) => theme.layout.xs};
    height: ${({ theme }) => theme.layout.xs};
    font-size: ${({ theme }) => theme.fontSize.xs};
    border-radius: ${radiusXxxl};
    background-color: ${({ theme }) => theme.palette.green100};
    display: inline-block;
    vertical-align: middle;
    margin-left: ${({ theme }) => theme.space.xs};

    svg {
      display: block;
      margin: auto;
      margin-top: ${({ theme }) => theme.space.xxs};
    }
  }
`

export const Status = styled.div<StatusProp>`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  display: inline-block;
  padding: 0px ${({ theme }) => theme.space.xs};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};

  ${({ theme, pending }) =>
    pending &&
    css`
      margin-left: ${theme.space.md};
    `}

  ${StyledIcon} {
    span {
      ${({ theme, pastDate }) =>
        pastDate &&
        css`
          background-color: ${theme.palette.blueGray50};
        `}

      ${({ theme, pending }) =>
        pending &&
        css`
          background-color: ${theme.palette.purple100};
        `}

      ${({ theme, cancelled }) =>
        cancelled &&
        css`
          background-color: ${theme.colors.uiError};
        `}
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0;
  }
`

export const LessonBadge = styled.span`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.warning100};
  border-radius: 20px;
  display: inline-block;
  text-align: center;
  color: ${({ theme }) => theme.palette.white};
  line-height: ${({ theme }) => theme.lineHeight.md};

  span {
    margin-top: ${({ theme }) => theme.space.xxxs};
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`

export const StyledButton = styled(Button)`
  padding: ${({ theme }) => `${theme.space.xs} ${theme.space.lg}`};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const RightCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;

  .margin-top-auto {
    margin-top: 18px;
  }

  .no-padding {
    padding: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: ${({ theme }) => theme.space.md};
  }
`
export const IndicatorCol = styled(Col)<{ onClick?: Function }>`
  margin-bottom: ${({ theme }) => theme.space.md};
  margin-left: ${({ theme }) => theme.space.md};
  &:last-of-type {
    margin-bottom: 0;
  }

  ${StyledStatus} {
    padding-top: ${({ theme }) => theme.space.xxs};
  }

  ${({ onClick }) =>
    typeof onClick === 'function'
      ? css`
          cursor: pointer;
        `
      : ''};

  button {
    padding: ${({ theme }) => `${theme.space.xs} ${theme.space.lg}`};
  }
`

export const EventJoinOnlineBtnWrapper = styled.div<{ smallSizes?: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.space.xs};
  flex-wrap: wrap;
  align-items: center;
  ${({ theme, smallSizes }) =>
    smallSizes &&
    `
      border-bottom: 1px solid ${theme.palette.gray40};
      margin-top: -7px;
      margin-left: -16px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 9px;
      width: calc(100% + 32px);
      margin-bottom: 13px;
  `}

  ${IndicatorCol} {
    margin: 0;
  }
`

export const EventOpenMaterialBtnWrapper = styled.div<{ smallSizes?: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.space.xs};
  flex-wrap: wrap;
  align-items: center;
  ${({ theme, smallSizes }) =>
    smallSizes &&
    `
      border-bottom: 1px solid ${theme.palette.gray40};
      margin-top: -7px;
      margin-left: -16px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 9px;
      width: calc(100% + 32px);
      margin-bottom: 13px;
  `}

  button {
    margin-top: 0;
  }

  ${IndicatorCol} {
    margin: 0;
  }

  ${({ theme }) => `
    button {
      color: ${theme.palette.white};
      background-color: ${theme.palette.blue100};

      &:hover,
      &:active,
      &:focus {
        background-color: ${theme.palette.blue80};
      }

      &:disabled {
        background-color: ${theme.palette.gray20};
        color: ${theme.palette.gray100};
        cursor: not-allowed;
      }

      span[data-key='icon-Resources'] {
        margin-right: ${theme.space.xs};
      }
    }
  `}
`

export const BPSStatus = styled.span`
  color: ${({ theme }) => theme.palette.success100};
`

export const StyledFirstClass = styled(FirstClass)`
  background-color: ${({ theme }) => theme.palette.blue100};
`

export const ContentBox = styled.div`
  padding: ${({ theme }) => theme.space.md};
  ${Col} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const Section = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.palette.blue20};
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.lg}`};
`
export const Content = styled.div`
  padding: ${({ theme }) => theme.space.md};
  border-top: 1px solid ${({ theme }) => theme.palette.gray100};
  ${Wrapper} {
    padding: 0;
  }
`

export const StyledPCol = styled(Col)`
  margin-bottom: ${({ theme }) => theme.space.md};
`

export const P = styled.p`
  &:first-of-type {
    margin: 0;
  }
`

export const CompletedTick = styled(Tick)`
  background-color: ${({ theme }) => theme.colors.brandSecondary};
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: 6px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.brandSecondary};
`

export const JoinLessonContainer = styled.div`
  margin-left: ${({ theme }) => theme.space.xs};
`

export const IndicatorsRow = styled(Row)`
  .i__my-sched_jlbtn {
    margin-bottom: ${({ theme }) => theme.space.md};
    margin-left: ${({ theme }) => theme.space.xs};
  }
  .i__my-sched_stbtn {
    flex: 1 0 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: -${({ theme }) => theme.space.xs};
  }
`

export const RecordTableOverride = styled.div`
  ${LessonRegistrationSection} {
    border-bottom: initial;
    padding: 0;
  }

  .no-padding {
    padding: 0 !imporant;
  }
`

export const AtRiskContainer = styled.div<{ isAtRisk?: boolean; colorIconCol?: boolean }>`
  height: 100%;

  ${({ isAtRisk = false, colorIconCol }) =>
    isAtRisk &&
    colorIconCol &&
    css`
      & div.card-left-col {
        background-color: #feeee2 !important;
        border-right: 1px solid #d87515 !important;
      }

      & div.at-risk-collapse-row {
        background-color: #feeee2;
        border-bottom-right-radius: ${({ theme }) => theme.radius.md};
        border-bottom-left-radius: ${({ theme }) => theme.radius.md};
      }
    `}
  ${({ isAtRisk = false }) =>
    isAtRisk &&
    css`
      & > div {
        border: 1px solid #d87515 !important;
      }

      & div.at-risk-collapse-row {
        background-color: #feeee2;
        border-bottom-right-radius: ${({ theme }) => theme.radius.md};
        border-bottom-left-radius: ${({ theme }) => theme.radius.md};
      }

      & .at-risk-text {
        svg {
          color: #d87515;
        }
        color: #d87515;
      }
    `}
`

export const MiniProgramName = styled.div`
  ${({ theme }) => `
      font-size: ${theme.fontSize.xs};
      letter-spacing: 0.56px;
      align-self: center;
      justify-content: center;
      padding-top: 1px;
  `}
`

export const GroupedName = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.space.xs};
`

export const AtRiskText = styled.div`
  svg {
    color: #d87515;
  }
  color: #d87515;
  display: flex;
  align-items: center;
`
