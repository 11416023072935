import { Cancelled, Pending, Tick, TickSquare } from '@berlitz/icons'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { LessonStatusProps, StatusProp } from './interface'
import { Status, StyledIcon } from './style'

const LessonStatus: React.FC<LessonStatusProps> = ({ status, customLabel }) => {
  function getLessonStatus(id: string, Icon: React.ElementType, statusProps: StatusProp) {
    return (
      <Status {...statusProps}>
        {customLabel ? customLabel : <FormattedMessage id={id} />}
        <StyledIcon>
          <Icon color="brand03" />
        </StyledIcon>
      </Status>
    )
  }

  switch ((status || '').toLowerCase()) {
    case 'completed':
      return getLessonStatus('Completed', TickSquare, { completed: true })

    case 'confirmed':
      return getLessonStatus('Confirmed', Tick, { confirmed: true })

    case 'pending':
      return getLessonStatus('Pending', Pending, { pending: true })

    case 'cancelled':
      return getLessonStatus('Cancelled', Cancelled, { cancelled: true })

    case 'rejected':
      return getLessonStatus('Rejected', Cancelled, { cancelled: true })

    default:
      return <></>
  }
}

export default LessonStatus
