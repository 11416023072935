import { ClassroomLocation, Profile, WarningTriangle } from '@berlitz/icons'
import Spacer from '@berlitz/spacer'
import Bus from '@berlitz/streamline-icons/lib/regular/47-Transportation/10-Bus/Bus'
import Building from '@berlitz/streamline-icons/lib/regular/49-Building-Construction/03-Buildings/Building1'
import { MyLessonEvent } from '@layouts/MyLessons/Lessons/style'
import { PROGRAM_TYPES } from '@utils/constants'
import Link from 'next/link'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'react-styled-flexboxgrid'

import { LessonInfoProp } from './interface'
import LessonStatus from './LessonStatus'
import { Actions, Indicators, LessoInfoItem, LessonDate, LessonInfoRow, Time, TravelTime, TimeCol } from './style'
import { EventAttendeeContext } from '@layouts/Events/context'
import { useContext } from 'react'

const identifyLocation = (location: React.ReactNode, type: string) => {
  const parseLocation = String(location)
  const tlc = (s: string = '') => s.toLowerCase()
  let loc = parseLocation === 'null' ? '' : parseLocation

  const lType = (type || '').toLowerCase()
  if (lType.includes('online') || PROGRAM_TYPES.ONLINE.includes(type)) {
    loc = 'Online'
  }
  return tlc(loc) === tlc('Live Online Virtual Center') ? 'Online' : loc
}

const LessonInfo: React.FC<LessonInfoProp> = ({
  Event,
  date,
  time,
  status,
  customStatusLabel,
  group,
  groupName,
  onGroupClick,
  location,
  type,
  travelTime,
  room,
  indicators,
  actionButtons,
  pendingButtons,
  atRiskData = [],
}) => {
  const userEventCtx = useContext(EventAttendeeContext)
  const recId = userEventCtx.RecordId ? `&recordId=${userEventCtx.RecordId}` : ''
  return (
    <LessonInfoRow>
      {actionButtons && (
        <Col xs={12} lg={false}>
          <Actions className={`${indicators && 'with-indicators'} ${status}`}>
            {indicators}
            {actionButtons}
          </Actions>
        </Col>
      )}
      {pendingButtons && (
        <Col xs={12} lg={false}>
          <Actions>{pendingButtons}</Actions>
        </Col>
      )}

      <Col xs={12} lg={7}>
        <Row>
          {Event ? (
            <MyLessonEvent>
              {Event?.Language && (
                <div id="delivery-language">
                  <div>
                    <FormattedMessage id="Delivery language" defaultMessage="Delivery language" />:
                  </div>
                  <div id="language">{Event?.Language}</div>
                </div>
              )}

              {Event?.EventDisplayName ? (
                <div className="with-name">
                  <div id="name">{Event?.EventDisplayName}</div>
                  <div className="date-time">
                    <div id="date">{date}</div>
                    <div id="middot">&middot;</div>
                    <div id="time">{time}</div>
                  </div>
                </div>
              ) : (
                <div className="date-time">
                  <div id="date">{date}</div>
                  <div id="time">{time}</div>
                </div>
              )}

              <Link
                href={`/events/?id=${Event?.EventId}&start=${Event?.StartDatetime}&end=${Event?.EndDatetime}${recId}`}
                id="more-info"
              >
                <FormattedMessage id="More info" defaultMessage="More info" />
              </Link>
            </MyLessonEvent>
          ) : (
            <>
              {date && (
                <Col xs={12}>
                  <LessonDate>{date}</LessonDate>
                  <Spacer size="sm" />
                </Col>
              )}
              <TimeCol xs={12}>
                <Time>{time}</Time>
              </TimeCol>
              <Col xs={12}>
                <LessoInfoItem className={`${status === 'cancelled' && 'dark-text'}`}>
                  {groupName && (
                    <>
                      <Profile className="group-name-icon" />
                      <a onClick={() => onGroupClick()}>{group}</a>
                    </>
                  )}
                </LessoInfoItem>
              </Col>
              <Col xs={12}>
                <LessoInfoItem className={`${status === 'cancelled' && 'dark-text'}`}>
                  <Building className="margin-right-8" /> <p>{identifyLocation(location, type)}</p>
                  {travelTime && (
                    <TravelTime>
                      <Bus className="margin-right-8" />
                      {travelTime}
                    </TravelTime>
                  )}
                </LessoInfoItem>
              </Col>
              {room && (
                <Col xs={12}>
                  <LessoInfoItem className={`${status === 'cancelled' && 'dark-text'}`}>
                    <ClassroomLocation className="margin-right-8" /> <p>{room}</p>
                  </LessoInfoItem>
                </Col>
              )}
              {Boolean(atRiskData.length) && (
                <Col xs={12}>
                  <LessoInfoItem className="at-risk-text">
                    {atRiskData.map((risk) => {
                      const values = {
                        student: risk.atRiskStudent,
                        lessonCount: risk.lessonsLeft,
                      }
                      return (
                        <>
                          <WarningTriangle className="margin-right-8" />
                          <p>
                            <FormattedMessage
                              id="{student} needs to book {lessonCount} lessons"
                              defaultMessage="{student} needs to book {lessonCount} lessons"
                              values={values}
                            />
                          </p>
                        </>
                      )
                    })}
                  </LessoInfoItem>
                </Col>
              )}
            </>
          )}
        </Row>
      </Col>
      <Col xs={12} lg={5}>
        <div className={`${status === 'Pending' && 'pending-actions'}`}>
          <Row>
            {status && status !== 'Confirmed' && status !== 'Completed' && customStatusLabel && (
              <Col xs={false} lg={12}>
                <Indicators>
                  {indicators}
                  <LessonStatus status={status} customLabel={customStatusLabel} />
                </Indicators>
              </Col>
            )}
            {actionButtons && (
              <>
                <Col xs={false} lg={3} />
                <Col xs={false} lg={9}>
                  <Actions className={`${!status && 'with-indicators'}`}>
                    {!status && indicators}
                    {actionButtons}
                  </Actions>
                </Col>
              </>
            )}
            {pendingButtons && (
              <>
                <Col xs={false} lg={9} />
                <Col xs={false} lg={3}>
                  <Actions>{pendingButtons}</Actions>
                </Col>
              </>
            )}
          </Row>
        </div>
      </Col>
    </LessonInfoRow>
  )
}

export default LessonInfo
