import { ArrowDown } from '@berlitz/icons'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Col } from 'react-styled-flexboxgrid'

import { CollapsableProps } from './interface'
import { CollapsableContainer, CollapsableContent, CollapsableRow, ExpanderIcon } from './style'

const Collapsable: React.FC<CollapsableProps> = ({ content, onCollapse = () => {} }) => {
  const [collapse, setCollapse] = React.useState(false)

  return (
    <CollapsableRow>
      {collapse && content && <CollapsableContent md={12}>{content()}</CollapsableContent>}
      <Col md={12} className="no-padding">
        <CollapsableContainer
          className="no-padding at-risk-collapse-row"
          onClick={() => {
            onCollapse(!collapse)
            setCollapse(!collapse)
          }}
        >
          <ExpanderIcon expanded={collapse}>
            <ArrowDown color="brand02" />
          </ExpanderIcon>
          {!collapse && <FormattedMessage id="Open" />}
          {collapse && <FormattedMessage id="Close" />}
        </CollapsableContainer>
      </Col>
    </CollapsableRow>
  )
}

export default Collapsable
