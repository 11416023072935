import {
  GroupFaceToFaceLarge,
  GroupHybrid,
  GroupOnline,
  OnlineGroupActivation,
  OnlinePrivateActivation,
  PrivateHybrid,
} from '@berlitz/icons'
import CalendarFavorite from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/21-Date-Calendar/CalendarFavorite'
import DesktopMonitorApprove from '@berlitz/streamline-icons/lib/regular/03-Computers-Devices-Electronics/02-Desktop-Computers/DesktopMonitorApprove'
import TeamMeetingMessageQuestion1 from '@berlitz/streamline-icons/lib/regular/07-Work-Office-Companies/02-Meetings-Collaboration/TeamMeetingMessageQuestion1'
import MeetingTeamMonitor1 from '@berlitz/streamline-icons/lib/regular/07-Work-Office-Companies/03-Video-Meetings/MeetingTeamMonitor1'
import TaskListEdit from '@berlitz/streamline-icons/lib/regular/07-Work-Office-Companies/05-Tasks/TaskListEdit'
import HumanResourcesHierarchy1 from '@berlitz/streamline-icons/lib/regular/07-Work-Office-Companies/09-Human-Resources/HumanResourcesHierarchy1'
import PresentationAnalytics from '@berlitz/streamline-icons/lib/regular/07-Work-Office-Companies/11-Presentations/PresentationAnalytics'
import PresentationAudience from '@berlitz/streamline-icons/lib/regular/07-Work-Office-Companies/11-Presentations/PresentationAudience'
import NotesCalendar from '@berlitz/streamline-icons/lib/regular/11-Content/03-Notes/NotesCalendar'
import NoteSettings from '@berlitz/streamline-icons/lib/regular/11-Content/03-Notes/NotesSettings'
import CommonFileTextCheck from '@berlitz/streamline-icons/lib/regular/16-Files-Folders/01-Common-Files/CommonFileTextCheck'
import ConversationChatBubble from '@berlitz/streamline-icons/lib/regular/21-Messages-Chat-Smileys/03-Conversation/ConversationChatBubble'
import ConversationChatText from '@berlitz/streamline-icons/lib/regular/21-Messages-Chat-Smileys/03-Conversation/ConversationChatText'
import TravelLuggage1 from '@berlitz/streamline-icons/lib/regular/23-Travel/01-Travel-Metaphor/TravelLuggage1'
import SchoolTestResults from '@berlitz/streamline-icons/lib/regular/36-School-Learning/01-School/SchoolTestResults'
import ELearningBookLaptop from '@berlitz/streamline-icons/lib/regular/36-School-Learning/03-E-Learning/ELearningBookLaptop'
import ReadHome from '@berlitz/streamline-icons/lib/regular/36-School-Learning/04-Library-Reading/ReadHome'
import ReadSearch from '@berlitz/streamline-icons/lib/regular/36-School-Learning/04-Library-Reading/ReadSearch'
import Compass1 from '@berlitz/streamline-icons/lib/regular/48-Maps-Navigation/01-Compass/Compass1'
import get from 'lodash/get'
import includes from 'lodash/includes'
import * as React from 'react'

import IconWrapper from '@components/IconWrapper'
import { useIntl } from 'react-intl'
import { LessonType } from './interface'
import { LessonTypeBox } from './style'

const LessonTypeIcon: React.FC<LessonType> = ({ typeName = '', darkText, darkIcon, customLabel = '', labelStyle }) => {
  const intl = useIntl()

  function getLessonType(Icon: React.ElementType, name?: string, text?: boolean, icon?: boolean) {
    const customTypeName = {
      'Berlitz Flex - Private Online Activation': intl.formatMessage({ id: 'Live coaching session' }),
    }

    return (
      <LessonTypeBox name={name} className={`${icon ? 'dark-icon' : ''}`} labelStyle={labelStyle}>
        <IconWrapper filled>
          <Icon className="icon" />
        </IconWrapper>
        <p className={`name ${text ? 'dark-text' : ''}`}>{customLabel || customTypeName[typeName] || name}</p>
      </LessonTypeBox>
    )
  }

  const typeNames = {
    'training session': 0,
    'private total immersion level based': 1,
    'private face to face': 1,
    'private face to face level based': 1,
    'one on one': 1,
    'private online': 3,
    'private hybrid': 24,
    'self paced w private online activation': 7,
    event: 8,
    'self paced w incenter group activation': 9,
    'self paced w online group activation': 10,
    orientation: 11,
    counselling: 12,
    'administrative tasks': 13,
    'material preparation': 14,
    'customer demo': 15,
    'placement test': 16,
    'placements and demos': 16,
    testing: 17,
    'professional development': 18,
    'cultural products': 19,
    'technical check': 20,
    'soft reservation': 21,
    'live coaching session': 1,
    'orientation session': 11,
    'berlitz flex - private online activation': 1,
    activity: 1,
    webinar: 3,
    'counselling session': 8,
    meeting: 1,
    'group conversation class': 1,
  }

  const loweredTypeName = (typeName || '').toLowerCase()
  let typeId = get(typeNames, loweredTypeName, 0)

  if (includes(loweredTypeName, 'group') && includes(loweredTypeName, 'face to face')) {
    typeId = 2
  }

  if (
    includes(loweredTypeName, 'private') &&
    includes(loweredTypeName, 'online') &&
    !includes(loweredTypeName, 'berlitz flex')
  ) {
    typeId = 3
  }

  if (includes(loweredTypeName, 'group') && includes(loweredTypeName, 'online')) {
    typeId = 4
  }

  if (includes(loweredTypeName, 'group') && includes(loweredTypeName, 'hybrid')) {
    typeId = 6
  }

  if (includes(loweredTypeName, 'self paced') && includes(loweredTypeName, 'incenter')) {
    typeId = 9
  }

  if (includes(loweredTypeName, 'self paced') && includes(loweredTypeName, 'online group')) {
    typeId = 10
  }

  if (includes(loweredTypeName, 'group large') && includes(loweredTypeName, 'face to face')) {
    typeId = 22
  }

  if (includes(loweredTypeName, 'group large') && includes(loweredTypeName, 'face to face')) {
    typeId = 22
  }

  if (includes(loweredTypeName, 'self paced') && includes(loweredTypeName, 'private online')) {
    typeId = 23
  }

  if (includes(loweredTypeName, 'testing - ')) {
    typeId = 17
  }

  switch (typeId) {
    case 1:
      return getLessonType(ConversationChatBubble, typeName, darkText, darkIcon)

    case 2:
      return getLessonType(PresentationAudience, typeName, darkText, darkIcon)

    case 3:
      return getLessonType(MeetingTeamMonitor1, typeName, darkText, darkIcon)

    case 4:
      return getLessonType(GroupOnline, typeName, darkText, darkIcon)

    case 5:
      return getLessonType(HumanResourcesHierarchy1, typeName, darkText, darkIcon)

    case 6:
      return getLessonType(GroupHybrid, typeName, darkText, darkIcon)

    case 7:
      return getLessonType(ELearningBookLaptop, typeName, darkText, darkIcon)

    case 8:
      return getLessonType(CalendarFavorite, typeName, darkText, darkIcon)

    case 9:
      return getLessonType(ReadHome, typeName, darkText, darkIcon)

    case 10:
      return getLessonType(OnlineGroupActivation, typeName, darkText, darkIcon)

    case 11:
      return getLessonType(Compass1, typeName, darkText, darkIcon)

    case 12:
      return getLessonType(ConversationChatText, typeName, darkText, darkIcon)

    case 13:
      return getLessonType(NoteSettings, typeName, darkText, darkIcon)

    case 14:
      return getLessonType(ReadSearch, typeName, darkText, darkIcon)

    case 15:
      return getLessonType(TeamMeetingMessageQuestion1, typeName, darkText, darkIcon)

    case 16:
      return getLessonType(TaskListEdit, typeName, darkText, darkIcon)

    case 17:
      return getLessonType(CommonFileTextCheck, typeName, darkText, darkIcon)

    case 18:
      return getLessonType(SchoolTestResults, typeName, darkText, darkIcon)

    case 19:
      return getLessonType(TravelLuggage1, typeName, darkText, darkIcon)

    case 20:
      return getLessonType(DesktopMonitorApprove, typeName, darkText, darkIcon)

    case 21:
      return getLessonType(NotesCalendar, typeName, darkText, darkIcon)

    case 22:
      return getLessonType(GroupFaceToFaceLarge, typeName, darkText, darkIcon)

    case 23:
      return getLessonType(OnlinePrivateActivation, typeName, darkText, darkIcon)

    case 24:
      return getLessonType(PrivateHybrid, typeName, darkText, darkIcon)

    default:
      return getLessonType(PresentationAnalytics, typeName, darkText, darkIcon)
  }
}

export default LessonTypeIcon
